import clsx from 'clsx';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';

import SocialsButtonV2 from '@/components/layout/SocialsButtonV2';
import TokenRateV2 from '@/components/TokenRateV2';
import { SwapscannerLogo } from '@/components/vector';
import ExternalLinkIcon from '@/components/vector/ExternalLinkIcon';
import { SCNR_ADDRESS } from '@/defines/token-address';
import { OUTLINKS } from '@/components/core/MenuConfigV3';

const LINKS = [
  {
    headline: 'External Links',
    children: OUTLINKS,
  },
  {
    headline: 'Contact',
    children: [{ name: 'contact@swapscanner.io', href: 'contact-link' }],
  },
];

export default function MainFooterV2() {
  const { t } = useTranslation('common');

  return (
    <footer className="bg-gray-50 dark:bg-gradient-to-tl from-gray-900 to-gray-950 relative">
      <div className="md:max-w-screen-md mx-auto px-6 py-20">
        <SwapscannerLogo className="h-8 w-auto" />

        <div className="md:flex flex-row justify-between mt-10 text-gray-500 dark:text-white font-normal text-base">
          <div>
            <p>
              <Trans
                i18nKey={'Footer-title'}
                t={t}
                components={{
                  color: (
                    <span className="font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#3EB9FF] to-[#2AC3A8]" />
                  ),
                }}
              />
            </p>

            <TokenRateV2 className="mt-10" address={SCNR_ADDRESS} />

            <SocialsButtonV2 className="mt-10" />
          </div>
          <div className="mt-10 md:mt-0 space-y-8 flex flex-col md:flex-row md:space-y-0 md:space-x-28 lg:space-x-32 md:mr-10">
            {LINKS.map((list) => (
              <div
                key={list.headline}
                className="flex flex-col space-y-5 text-gray-500 dark:text-gray-400 text-xs font-medium lg:w-24"
              >
                <p className={clsx(list.headline === 'Swapscanner' ? 'hidden md:block' : '')}>
                  {list.headline}
                </p>
                {list.children.map((link, idx) => {
                  const isSwapscannerSection = list.headline === 'Swapscanner';
                  const isExternalSection = list.headline === 'External Links';
                  const aProps = isExternalSection
                    ? { target: '_blank', rel: 'noreferrer noopener' }
                    : {};

                  return (
                    <Link href={t(link.href)} key={link.href + idx}>
                      <a
                        className={clsx(
                          'flex flex-row items-center space-x-2 whitespace-nowrap font-medium text-sm text-gray-700 dark:text-white min-w-max hover:underline hover:opacity-70',
                          { 'hidden md:block': isSwapscannerSection },
                        )}
                        {...aProps}
                      >
                        <span>{link.name}</span>
                        <ExternalLinkIcon
                          className={clsx('h-3 w-3', { hidden: !isExternalSection })}
                        />
                      </a>
                    </Link>
                  );
                })}
              </div>
            ))}
          </div>
        </div>

        <div className="mt-14 flex items-center space-x-4">
          <Link href="/privacy-policy">
            <a className="text-gray-700 dark:text-white font-normal text-sm hover:underline">
              {t('privacy-policy-title')}
            </a>
          </Link>
          <Link href="/terms-of-use">
            <a className="text-gray-700 dark:text-white font-normal text-sm hover:underline">
              {t('terms-of-use-title')}
            </a>
          </Link>
        </div>

        <p className="text-gray-400 font-normal text-sm mt-1">
          Copyright © 2021-present Swapscanner. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
