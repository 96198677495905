import clsx from 'clsx';
import { Trans, useTranslation } from 'next-i18next';

import MediumIcon from '@/components/vector/MediumIcon';
import TelegramIcon from '@/components/vector/TelegramIcon';
import TwitterIcon from '@/components/vector/TwitterIcon';

import s from './SocialsButtonV2.module.css';

const MEDIUM = 'Medium';
const TELEGRAM = 'Telegram';
const TWITTER = 'Twitter';

const SOCIALS = {
  // [MEDIUM]: 'https://medium.com/@Swapscanner',
  [TELEGRAM]: {
    eng: 'https://t.me/Swapscanner_en',
    kor: 'https://t.me/Swapscanner_kr',
    com: 'https://t.me/Swapscanner_comm',
  },
  [TWITTER]: 'https://twitter.com/Swapscanner',
};

export default function SocialsButtonV2({ className }: { className?: string }) {
  const { t } = useTranslation('common');

  return (
    <div className={clsx(className, 'flex flex-col space-y-5')}>
      {/* <div>
        <a href={SOCIALS[MEDIUM]} target="_blank" rel="noreferrer noopener">
          <div className={s['title-layout']}>
            <MediumIcon className={s['social-icon']} />
            <span className={s['title']}>{MEDIUM}</span>
          </div>
          <span className={s['description']}>
            <Trans i18nKey={'Medium-description'} t={t} />
          </span>
        </a>
      </div> */}
      <div>
        <div className={s['title-layout']}>
          <TelegramIcon className={s['social-icon']} />
          <span className={s['title']}>{TELEGRAM}</span>
        </div>
        <span className={s['description']}>
          <a href={SOCIALS[TELEGRAM].kor} target="_blank" rel="noreferrer noopener">
            <Trans i18nKey={'Official(Kor)'} t={t} />
          </a>
          &nbsp;&middot;&nbsp;
          <a href={SOCIALS[TELEGRAM].eng} target="_blank" rel="noreferrer noopener">
            <Trans i18nKey={'Official(Eng)'} t={t} />
          </a>
          &nbsp;&middot;&nbsp;
          <a href={SOCIALS[TELEGRAM].com} target="_blank" rel="noreferrer noopener">
            <Trans i18nKey={'Community'} t={t} />
          </a>
        </span>
      </div>
      <div>
        <a href={SOCIALS[TWITTER]} target="_blank" rel="noreferrer noopener">
          <div className={s['title-layout']}>
            <TwitterIcon className={s['social-icon']} />
            <span className={s['title']}>{TWITTER}</span>
          </div>
          <span className={s['description']}>
            <Trans i18nKey={'Twitter-description'} t={t} />
          </span>
        </a>
      </div>
    </div>
  );
}
