export default function sparklePriceChange({ address, diff }: { address: string; diff: number }) {
  if (!diff || isNaN(diff)) return;

  const sbjs = document.getElementsByClassName(`price_${address}`);

  if (!sbjs) return;

  // add empty space to className for the convinience
  const ringColor = ' ' + (diff > 0 ? '!ring-green-500' : '!ring-red-500');
  const transition = ' transition duration-150 ease-out';

  for (let i = 0; i < sbjs.length; i++) {
    sbjs[i].className = sbjs[i].className.split(transition)[0] + ringColor;
  }

  setTimeout(() => {
    for (let i = 0; i < sbjs.length; i++) {
      sbjs[i].className = sbjs[i].className.split(ringColor)[0] + transition;
    }
  }, 300);
}
