interface Props {
  className?: string;
}

export default function ExternalLinkIcon({ className }: Props) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 512 512"
    >
      <path fill="currentColor" d="M400 464H48V104h192V72H16v424h416V272h-32v192z" />
      <path
        fill="currentColor"
        d="M304 16v32h137.373L188.687 300.687l22.626 22.626L464 70.627V208h32V16H304z"
      />
    </svg>
  );
}
