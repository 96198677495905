import { curveMonotoneX } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { GridRows } from '@visx/grid';
import { scaleTime, scaleLinear } from '@visx/scale';
import { AreaClosed, Area } from '@visx/shape';
import { useMemo } from 'react';

import { useSettingsContext } from '@/context/settings';
import { useId } from '@/hooks/use-id';

import type { LineData } from '@/lib/spline';

const DAY = 1000 * 60 * 60 * 24;

// data accessors
const getX = (d: LineData) => d[1];
const getY = (d: LineData) => d[0];

type SplineChartProps = {
  data: LineData[];
  width?: number;
  height?: number;
  className?: string;
  strokeWidth?: number;
  color?: string;
  gradientOpacity?: number;
  lastValueGrid?: boolean;
  bottomOffset?: number;
  todayOpenPrice?: number;
};

export const SplineChart: React.FC<SplineChartProps> = ({
  data,
  width = 600,
  height = 240,
  className,
  strokeWidth = 2,
  color = '#606E83',
  gradientOpacity = 1,
  lastValueGrid = false,
  bottomOffset = 0.5,
  todayOpenPrice,
}) => {
  const { themeMode } = useSettingsContext();

  const backgroundId = `spline-background-${useId()}`;

  // To make sure the data is always sorted by x, we sort it here.
  const lineData = useMemo<LineData[]>(
    () => data.filter(([value]) => !!value).sort((a, b) => a[1] - b[1]),
    [data],
  );

  const yScale = useMemo(() => {
    const min = Math.min(...lineData.map(getY));
    const max = Math.max(...lineData.map(getY));

    return scaleLinear({
      range: [height, 0],
      domain: [min - (max - min) * bottomOffset, max],
      nice: true,
    });
  }, [lineData, height, bottomOffset]);

  const xScale = useMemo(() => {
    const xScale = scaleTime<number>({
      domain: [new Date(getX(lineData[0])), new Date(getX(lineData[lineData.length - 1]))],
    });
    xScale.range([0, width]);
    return xScale;
  }, [lineData, width]);

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="none"
    >
      <LinearGradient id={backgroundId} from={color} to={color} toOpacity={0} />
      {lastValueGrid && (
        <GridRows
          scale={yScale}
          width={width}
          tickValues={[getY(lineData[lineData.length - 1])]}
          strokeDasharray="4,4"
          stroke={color}
          opacity={0.8}
          vectorEffect="non-scaling-stroke"
        />
      )}
      {todayOpenPrice && (
        <GridRows
          scale={yScale}
          width={width}
          tickValues={[todayOpenPrice]}
          strokeDasharray="4,4"
          stroke={themeMode === 'dark' ? '#475364' : '#C9D1DC'}
          opacity={0.8}
          vectorEffect="non-scaling-stroke"
        />
      )}
      <Area<LineData>
        data={lineData}
        x={(d) => xScale(getX(d)) ?? 0}
        y={(d) => yScale(getY(d)) ?? 0}
        scale="linear"
        curve={curveMonotoneX}
        stroke={color}
        strokeWidth={strokeWidth}
        vectorEffect="non-scaling-stroke"
      />
      <AreaClosed<LineData>
        data={lineData}
        x={(d) => xScale(getX(d)) ?? 0}
        y={(d) => yScale(getY(d)) ?? 0}
        yScale={yScale}
        fill={`url(#${backgroundId})`}
        fillOpacity={gradientOpacity}
        curve={curveMonotoneX}
      />
      {/* GLYPH CIRCLE */}
      {todayOpenPrice && (
        <>
          <path
            d={`M ${xScale(Math.floor(Date.now() / DAY) * DAY)} ${yScale(
              todayOpenPrice,
            )} l 0.0001 0`}
            vectorEffect="non-scaling-stroke"
            strokeWidth={12}
            strokeLinecap="round"
            stroke={themeMode === 'dark' ? '#475364' : '#C9D1DC'}
          />
          <path
            d={`M ${xScale(Math.floor(Date.now() / DAY) * DAY)} ${yScale(
              todayOpenPrice,
            )} l 0.0001 0`}
            vectorEffect="non-scaling-stroke"
            strokeWidth={10}
            strokeLinecap="round"
            stroke={themeMode === 'dark' ? '#171B25' : '#F6F7FA'}
          />
        </>
      )}
    </svg>
  );
};
