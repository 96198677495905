import MainFooterV3 from '@/components/core/MainFooterV3';
import MainHeaderV3 from '@/components/core/MainHeaderV3';

import type { ReactNode } from 'react';
export default function MainLayout({ children }: { children: ReactNode }) {
  return (
    <div className="min-h-[1px]">
      <MainHeaderV3 />
      {children}
      <MainFooterV3 />
    </div>
  );
}
